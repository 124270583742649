import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layouts/NoteLayout.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "advent-of-code-2020",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#advent-of-code-2020",
        "aria-label": "advent of code 2020 permalink",
        "className": "remark-autolink-headers__anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "viewBox": "0 0 24 24",
          "fill": "none",
          "stroke": "currentColor",
          "strokeWidth": "2"
        }}><path parentName="svg" {...{
            "d": "M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71"
          }}></path><path parentName="svg" {...{
            "d": "M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71"
          }}></path></svg></a>{`Advent of Code 2020`}</h1>
    <ul>
      <li parentName="ul">{``}<a parentName="li" {...{
          "href": "/notes/aoc-2020-unison",
          "title": "aoc-2020-unison"
        }}>{`aoc-2020-unison`}</a>{``}</li>
      <li parentName="ul">{``}<a parentName="li" {...{
          "href": "/notes/day-1-report-repair",
          "title": "day-1-report-repair"
        }}>{`day-1-report-repair`}</a>{``}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      